import { AnyType } from "@/types";

const labels: AnyType = {
  ref_id: "Reference ID",
  comments1: "Comments 1",
  comments2: "Comments 2",
  dock_hours: "Dock Hours",
  pickup_hours: "Pickup Hours",
  truck: "Truck",
  ref: "Ref",
  commodity: "Commodity",
  pickUpDate: "Pickup Date",
  rate: "Rate",
  weight: "Weight",
};

const transform = (data: any) => {
  const result: AnyType = {};

  Object.keys(data).forEach((item: string) => {
    const value = data[item];
    const label = labels[item];

    if (value) {
      result[label || item] = value;
    }
  });
  return result;
};

export default transform;
