import { cn } from "@nextui-org/react";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  size?: "sm" | "md";
  color?: "gray" | "dark";
  isQuote?: boolean;
  quoteColor?: "danger" | "warning" | "success" | "info";
}

export const ArticleWrapper: FC<Props> = ({
  children,
  size = "md",
  color = "black",
  isQuote = false,
  quoteColor = "info",
}) => {
  const className = cn(
    "w-full flex flex-col items-center gap-3 text-gray-900 [&_*]:w-full [&_div]:py-2 [&_h5]:text-center [&_small]:text-sm [&_td]:w-min first:[&_td]:shrink-0 first:[&_td]:w-28 [&_td]:border [&_td]:p-1 [&_table]:border-collapse [&_a]:text-brand-700 hover:[&_a]:underline [&_img]:w-auto",
    {
      sm: "text-sm",
      md: "text-base",
    }[size],
    {
      gray: "text-gray-700",
      dark: "text-gray-100",
    }[color],
    {
      "pl-4 border-l-2": isQuote,
    },
    {
      danger: "border-danger-500",
      warning: "border-warning-500",
      success: "border-success-500",
      info: "border-info-500",
    }[quoteColor]
  );

  return <div className={className}>{children}</div>;
};
