"use client";

import { Modal, ModalContent } from "@nextui-org/modal";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import MainService from "./main";
import SMTPService from "./smtp";
import { Account } from "@/types";
const API_URL = process.env.REACT_APP_API_CARRIERIFY_PREFIX;

const ServiceModal = ({
  account,
  isOpen,
  onClose,
  onAddService,
}: {
  isOpen: boolean;
  onClose: any;
  onAddService?: any;
  account: Account | null;
}) => {
  const { accessToken } = useSelector(
    (state: RootState) => state.authReducer.value
  );
  const [smtpMode, setSmtpMode] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen === true && !account) setSmtpMode(false);
    else if (account) setSmtpMode(true);
  }, [isOpen, account]);

  const handleRedirect = (type: string) => {
    switch (type) {
      case "GOOGLE":
        return (window.location.href = `${API_URL}/account/google?accessToken=${accessToken}`);
      case "OUTLOOK":
        return (window.location.href = `${API_URL}/account/outlook?accessToken=${accessToken}`);
      default:
        setSmtpMode(true);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={smtpMode ? "3xl" : "md"}
        classNames={{
          header: "px-6 py-4 border-b rounded-t dark:border-gray-600",
          closeButton: "text-base hover:bg-blue-200 dark:border-gray-600",
        }}
      >
        <ModalContent>
          {smtpMode ? (
            <SMTPService
              account={account}
              onAddSMTP={() => {
                onClose();
                onAddService();
              }}
              onBack={() => (account ? onClose() : setSmtpMode(false))}
            />
          ) : (
            <MainService onRedirect={handleRedirect} />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ServiceModal;
