import { Progress } from "@nextui-org/react";

export const Loader = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-4">
      <h4>Wait a second...</h4>
      <Progress
        size="sm"
        isIndeterminate
        aria-label="Loading..."
        className="max-w-md"
      />
    </div>
  );
};
