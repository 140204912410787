import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import { useEffect, useState } from "react";

import Lottie from "react-lottie";
import successConfettiAnimation from "@/assets/animations/success-confetti.json";

import { ReactComponent as Logo } from "@/assets/svg/colored-logo.svg";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: successConfettiAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const ReloadLoadboardModal = ({ show }: { show: boolean }) => {
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    const needToShow = !window.localStorage.getItem("new_user");

    if (needToShow && show) {
      setShow(true);
    }
  }, [show]);

  const handleClose = () => {
    window.localStorage.setItem("new_user", "false");
    setShow(false);
  };

  return (
    <Modal
      size="2xl"
      isOpen={isShow}
      onOpenChange={setShow}
      onClose={handleClose}
      className="p-0 rounded-3xl"
      hideCloseButton={true}
    >
      <ModalContent className="p-6 pt-">
        <ModalBody className="p-0 text-base text-gray-900 mb-3 w-full">
          <div className="absolute left-0 top-0 z-0">
            <Lottie options={defaultOptions} height={400} width={672} />
          </div>
          <div className="z-10 flex flex-col items-center gap-6 py-10">
            <h2 className="flex items-center gap-2">
              Welcome to
              <Logo className="w-56 h-12" />
            </h2>
            <div className="flex flex-col items-center gap-3">
              <h3>
                Now{" "}
                <span className="text-brand-700">Refresh your loadbord</span>
              </h3>

              <p className="text-center">
                Your loadboard has been upgraded with our extension.
                <br />
                Please reload loadboard page to start using new features.
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-center p-0">
          <Button color="primary" onClick={handleClose}>
            Let's go
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
