export const mergetags_list = [
  {
    value: "company",
    title: "Company",
  },
  {
    value: "do",
    title: "Deadhead",
  },
  {
    value: "dest",
    title: "Destination",
  },
  {
    value: "email",
    title: "Email",
  },
  {
    value: "fp",
    title: "Fp",
  },
  {
    value: "origin",
    title: "Origin",
  },
  {
    value: "avail",
    title: "Pickup Date",
  },
  {
    value: "rate",
    title: "Rate",
  },
  {
    value: "ref",
    title: "Reference Number",
  },
  {
    value: "trip",
    title: "Trip",
  },
  {
    value: "truck",
    title: "Truck type",
  },
];

export const toolbar =
  "undo redo | blocks | bold italic underline forecolor | link image | lineheight align | mergetags | code removeformat | preview";

export const plugins = [
  "advcode",
  "advlist",
  "advtemplate",
  "anchor",
  "autolink",
  "charmap",
  "code",
  "editimage",
  "emoticons",
  "fullscreen",
  "help",
  "image",
  "inlinecss",
  "insertdatetime",
  "link",
  "linkchecker",
  "lists",
  "markdown",
  "mergetags",
  "preview",
  "quickbars",
  "searchreplace",
  "table",
  "visualblocks",
  "wordcount",
];
