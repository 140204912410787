import { DragDropContext, Droppable } from "react-beautiful-dnd";

const DragContent = ({ onDrop, children }: { onDrop?: any; children: any }) => {
  return (
    <DragDropContext onDragEnd={onDrop}>
      <Droppable droppableId="list-container">
        {(provided) => (
          <div
            className="flex flex-col w-full"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragContent;
