import { Button } from "@nextui-org/react";

import paymentErrorAnimation from "@/assets/animations/payment-error.json";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: paymentErrorAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const ErrorPlan = () => {
  const accessToken = useSelector(
    (state: RootState) => state.authReducer.value.accessToken
  );

  return (
    <>
      <Lottie options={defaultOptions} height={400} width={400} />

      <h2 className="font-semibold text-2xl mb-6">
        Oh no, your payment failed
      </h2>

      <p className="font-medium text-lg text-center text-gray-600 mb-6 max-w-xs">
        Don't worry, you can update your payment method
      </p>

      <Button
        size="lg"
        className="w-fit"
        color="primary"
        onClick={() => {
          fetch("https://api.carrierify.com/users/billing-portal", {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.text())
            .then((url) => {
              if (url) {
                window.location.href = url;
              } else {
                console.error("Failed to get redirect URL");
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      >
        Update Payment Method
      </Button>
    </>
  );
};
