import * as React from "react";
import { IconSvgProps } from "@/types";

const GoogleIcon: React.FC<IconSvgProps> = ({ size = 20 }) => (
  <svg
    viewBox="0 0 312 312"
    width={size}
    height={size}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
  >
    <path
      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
      fill="#4285F4"
    />
    <path
      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
      fill="#34A853"
    />
    <path
      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
      fill="#FBBC05"
    />
    <path
      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
      fill="#EB4335"
    />
  </svg>
);

const OutlookIcon: React.FC<IconSvgProps> = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <title>OfficeCore10_32x_24x_20x_16x_01-22-2019</title>
    <g id="STYLE_COLOR" data-name="STYLE = COLOR">
      <path
        d="M28.59554,2H11.404A1.404,1.404,0,0,0,10,3.40407L10,5l9.69,3L29.99961,5v-1.596A1.40405,1.40405,0,0,0,28.59554,2Z"
        fill="#0364b8"
      />
      <path
        d="M31.6499,17.40548A11.34148,11.34148,0,0,0,32,16.00043a.66592.66592,0,0,0-.33331-.57679v-.00048l-.01282-.00727-.004-.00225L20.81213,9.2395a1.49877,1.49877,0,0,0-.14532-.08276V9.15668a1.5003,1.5003,0,0,0-1.33331,0l-.00006.00006a1.49088,1.49088,0,0,0-.14527.08276L8.3504,15.41364l-.004.00225-.01275.00727v.00048a.666.666,0,0,0-.33331.57679,11.34354,11.34354,0,0,0,.35009,1.405l11.49171,8.405Z"
        fill="#0a2767"
      />
      <polygon
        points="24 5 17 5 14.979 8 17 11 24 17 30 17 30 11 24 5"
        fill="#28a8ea"
      />
      <rect x="10" y="5" width="6.99985" height="5.99999" fill="#0078d4" />
      <rect
        x="23.99969"
        y="5"
        width="5.99992"
        height="5.99997"
        fill="#50d9ff"
      />
      <polygon
        points="24 17 17 11 10 11 10 17 17 23 27.832 24.768 24 17"
        fill="#0364b8"
      />
      <line x1="10.03118" y1="5.00001" x2="29.99961" y2="5" fill="none" />
      <rect
        x="16.99971"
        y="11"
        width="7.00001"
        height="5.99999"
        fill="#0078d4"
      />
      <rect x="10" y="17" width="6.99978" height="6" fill="#064a8c" />
      <rect x="23.99973" y="17" width="5.99992" height="6" fill="#0078d4" />
      <path
        d="M20.19093,25.21763,8.39736,16.61718,8.8915,15.748s10.745,6.12012,10.90905,6.21191a.5285.5285,0,0,0,.4209-.0122c.15332-.08643,10.93249-6.23,10.93249-6.23l.49609.86914Z"
        fill="#0a2767"
        opacity="0.5"
      />
      <path
        d="M31.66669,16.57678v.00049l-.01325.00757-.00335.00189-10.838,6.1742a1.49672,1.49672,0,0,1-1.46045.09118L23.12632,27.913,31.38037,29.71l-.0003.00446A1.49756,1.49756,0,0,0,32,28.5V16.00006A.666.666,0,0,1,31.66669,16.57678Z"
        fill="#1490df"
      />
      <path
        d="M32,28.5v-.738l-9.98291-5.68756-1.205.68647a1.49685,1.49685,0,0,1-1.46045.09118L23.12634,27.913l8.254,1.79694-.0003.00446A1.49756,1.49756,0,0,0,32,28.5Z"
        opacity="0.05"
      />
      <path
        d="M31.95,28.88342,21.00708,22.64984l-.19495.11109a1.49685,1.49685,0,0,1-1.46045.09118L23.12634,27.913l8.254,1.79694-.0003.00446A1.50132,1.50132,0,0,0,31.95,28.88342Z"
        opacity="0.1"
      />
      <path
        d="M8.35,16.59v-.01H8.34l-.03-.02A.65068.65068,0,0,1,8,16V28.5A1.498,1.498,0,0,0,9.5,30h21a1.50264,1.50264,0,0,0,.37-.05.63693.63693,0,0,0,.18-.06.14177.14177,0,0,0,.06-.02,1.04752,1.04752,0,0,0,.23-.13c.02-.01.03-.01.04-.03Z"
        fill="#28a8ea"
      />
      <path
        d="M18,24.66663V8.33331A1.33727,1.33727,0,0,0,16.66663,7h-6.6355v4h.00006v3.456l-1.68085.95759-.004.00225-.01281.00727v.00048a.66591.66591,0,0,0-.33332.57679l.00019.00421L8,16.001V26h8.66663A1.33732,1.33732,0,0,0,18,24.66663Z"
        opacity="0.1"
      />
      <path
        d="M17,25.66663V9.33331A1.33727,1.33727,0,0,0,15.66663,8h-5.6355v3h.00006v3.456l-1.68085.95759-.004.00225-.01281.00727v.00048a.66591.66591,0,0,0-.33332.57679l.00019.00421L8,16.001V27h7.66663A1.33732,1.33732,0,0,0,17,25.66663Z"
        opacity="0.2"
      />
      <path
        d="M17,23.66663V9.33331A1.33727,1.33727,0,0,0,15.66663,8h-5.6355v3h.00006v3.456l-1.68085.95759-.004.00225-.01281.00727v.00048a.66591.66591,0,0,0-.33332.57679l.00019.00421L8,16.001V25h7.66663A1.33732,1.33732,0,0,0,17,23.66663Z"
        opacity="0.2"
      />
      <path
        d="M16,23.66663V9.33331A1.33727,1.33727,0,0,0,14.66663,8h-4.6355v3h.00006v3.456l-1.68085.95759-.004.00225-.01281.00727v.00048a.66591.66591,0,0,0-.33332.57679l.00019.00421L8,16.001V25h6.66663A1.33732,1.33732,0,0,0,16,23.66663Z"
        opacity="0.2"
      />
      <path
        id="Back_Plate"
        data-name="Back Plate"
        d="M1.33348,8H14.66668A1.33332,1.33332,0,0,1,16,9.33332V22.66666A1.33333,1.33333,0,0,1,14.66667,24H1.33347A1.33332,1.33332,0,0,1,.00015,22.66667V9.33333A1.33333,1.33333,0,0,1,1.33348,8Z"
        fill="#0078d4"
      />
      <path
        d="M3.86661,13.468a4.18142,4.18142,0,0,1,1.64192-1.8139A4.9652,4.9652,0,0,1,8.11808,11a4.61691,4.61691,0,0,1,2.414.62036,4.13906,4.13906,0,0,1,1.59809,1.733,5.59694,5.59694,0,0,1,.55967,2.54889,5.90136,5.90136,0,0,1-.57653,2.66689A4.239,4.239,0,0,1,10.468,20.36277,4.79917,4.79917,0,0,1,7.963,21a4.72864,4.72864,0,0,1-2.46794-.62711,4.20422,4.20422,0,0,1-1.61833-1.73635A5.4589,5.4589,0,0,1,3.31031,16.118,6.05522,6.05522,0,0,1,3.86661,13.468Zm1.74981,4.25826a2.71627,2.71627,0,0,0,.92379,1.19352,2.41142,2.41142,0,0,0,1.443.43493A2.533,2.533,0,0,0,9.524,18.90627a2.60284,2.60284,0,0,0,.89682-1.1969,4.62569,4.62569,0,0,0,.28658-1.66554,5.06271,5.06271,0,0,0-.26972-1.68578,2.6687,2.6687,0,0,0-.86648-1.24073,2.3873,2.3873,0,0,0-1.52729-.472,2.493,2.493,0,0,0-1.47672.4383,2.741,2.741,0,0,0-.944,1.20364,4.77582,4.77582,0,0,0-.00674,3.439Z"
        fill="#fff"
      />
      <rect x="0.00002" width="32" height="32" fill="none" />
    </g>
  </svg>
);

const DoneIcon: React.FC<IconSvgProps> = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    className="text-green-600 w-20 h-20"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="1"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const MailIcon: React.FC<IconSvgProps> = ({ size = 24, color = "none" }) => (
  <svg
    className={color}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
  </svg>
);

const TemplateIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M1.5 7.125c0-1.036.84-1.875 1.875-1.875h6c1.036 0 1.875.84 1.875 1.875v3.75c0 1.036-.84 1.875-1.875 1.875h-6A1.875 1.875 0 011.5 10.875v-3.75zm12 1.5c0-1.036.84-1.875 1.875-1.875h5.25c1.035 0 1.875.84 1.875 1.875v8.25c0 1.035-.84 1.875-1.875 1.875h-5.25a1.875 1.875 0 01-1.875-1.875v-8.25zM3 16.125c0-1.036.84-1.875 1.875-1.875h5.25c1.036 0 1.875.84 1.875 1.875v2.25c0 1.035-.84 1.875-1.875 1.875h-5.25A1.875 1.875 0 013 18.375v-2.25z"
      clipRule="evenodd"
    />
  </svg>
);

const BookmarkIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
    <g>
      <path
        id="Icon"
        d="M4.16797 4.16667C4.16797 3.24619 4.91416 2.5 5.83464 2.5H14.168C15.0884 2.5 15.8346 3.24619 15.8346 4.16667V17.5L10.0013 14.5833L4.16797 17.5V4.16667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const BookmarkFillIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="currentColor">
    <g>
      <path
        id="Icon"
        d="M4.16797 4.16667C4.16797 3.24619 4.91416 2.5 5.83464 2.5H14.168C15.0884 2.5 15.8346 3.24619 15.8346 4.16667V17.5L10.0013 14.5833L4.16797 17.5V4.16667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const ReviewIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    height={size}
    width={size}
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 11.5v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z"></path>
  </svg>
);

const BellIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="heroicons-outline/bell">
      <path
        id="Vector"
        d="M12.3812 14.2348C13.96 14.0475 15.4824 13.6763 16.9264 13.1432C15.7291 11.8142 15.0003 10.0547 15.0003 8.125V7.54099C15.0004 7.52734 15.0005 7.51368 15.0005 7.5C15.0005 4.73858 12.7619 2.5 10.0005 2.5C7.23906 2.5 5.00049 4.73858 5.00049 7.5L5.00032 8.125C5.00032 10.0547 4.27155 11.8142 3.07422 13.1432C4.51832 13.6763 6.04085 14.0475 7.61977 14.2348M12.3812 14.2348C11.6005 14.3274 10.806 14.375 10.0003 14.375C9.1948 14.375 8.40039 14.3274 7.61977 14.2348M12.3812 14.2348C12.4587 14.4759 12.5005 14.7331 12.5005 15C12.5005 16.3807 11.3812 17.5 10.0005 17.5C8.61978 17.5 7.50049 16.3807 7.50049 15C7.50049 14.7331 7.54231 14.476 7.61977 14.2348"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const PlusIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    height={size}
    width={size}
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
  </svg>
);

const CrossIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 5C11.0523 5 11.5 5.44772 11.5 6V9L14.5 9C15.0523 9 15.5 9.44772 15.5 10C15.5 10.5523 15.0523 11 14.5 11H11.5V14C11.5 14.5523 11.0523 15 10.5 15C9.94771 15 9.5 14.5523 9.5 14V11H6.5C5.94772 11 5.5 10.5523 5.5 10C5.5 9.44771 5.94772 9 6.5 9L9.5 9V6C9.5 5.44772 9.94771 5 10.5 5Z"
      fill="white"
    />
  </svg>
);

const GMailIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="52 42 88 66"
  >
    <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6" />
    <path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15" />
    <path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2" />
    <path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92" />
    <path
      fill="#c5221f"
      d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"
    />
  </svg>
);

const YahooIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 512 512"
    fill="#fff"
  >
    <rect width="512" height="512" rx="15%" fill="#5f01d1" />
    <g fill="#fff">
      <path d="M203 404h-62l25-59-69-165h63l37 95 37-95h62m58 76h-69l62-148h69" />
      <circle cx="303" cy="308" r="38" />
    </g>
  </svg>
);

const SMTPIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      fill="#329199"
      stroke="none"
    >
      <path d="M156 978 c-14 -19 -16 -63 -16 -280 l0 -257 -27 -3 -28 -3 0 -170 0 -170 28 -3 c20 -3 27 -9 27 -25 0 -13 7 -33 16 -45 15 -22 15 -22 344 -22 329 0 329 0 344 22 9 12 16 32 16 45 0 16 7 22 28 25 l27 3 0 170 0 170 -27 3 -28 3 0 257 c0 217 -2 261 -16 280 -15 22 -15 22 -344 22 -329 0 -329 0 -344 -22z m654 -163 l0 -35 -200 0 -200 0 0 35 0 35 200 0 200 0 0 -35z m0 -140 l0 -35 -200 0 -200 0 0 35 0 35 200 0 200 0 0 -35z m0 -140 l0 -35 -200 0 -200 0 0 35 0 35 200 0 200 0 0 -35z m-557 -151 c9 -3 13 -13 10 -25 -4 -13 -11 -18 -20 -15 -40 16 -60 16 -71 3 -18 -21 -5 -44 31 -56 67 -24 88 -80 47 -121 -17 -17 -31 -20 -78 -18 -50 3 -57 6 -60 26 -4 25 -3 26 34 11 74 -27 102 30 32 63 -65 31 -83 82 -42 123 15 15 88 21 117 9z m126 -76 c14 -42 28 -79 31 -82 3 -3 19 33 34 80 l29 84 188 0 c176 0 190 -1 209 -20 48 -48 9 -130 -62 -130 l-38 0 0 -45 c0 -38 -3 -45 -20 -45 -19 0 -20 7 -20 105 l0 105 -35 0 -35 0 0 -105 0 -105 -25 0 -25 0 0 105 0 105 -35 0 -35 0 0 -105 0 -105 -25 0 c-24 0 -25 2 -26 78 l0 77 -28 -75 c-23 -64 -31 -75 -51 -75 -19 0 -27 11 -49 75 l-26 74 -3 -77 c-3 -69 -5 -77 -22 -77 -19 0 -20 8 -20 121 l0 120 32 -3 c30 -3 33 -7 57 -80z" />
      <path d="M770 315 c0 -48 11 -56 51 -35 40 22 14 80 -35 80 -12 0 -16 -11 -16 -45z" />
    </g>
  </svg>
);

const TrashIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
      stroke="#FF0080"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M7.08331 4.14169L7.26665 3.05002C7.39998 2.25835 7.49998 1.66669 8.90831 1.66669H11.0916C12.5 1.66669 12.6083 2.29169 12.7333 3.05835L12.9166 4.14169"
      stroke="#FF0080"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M15.7084 7.61664L15.1667 16.0083C15.075 17.3166 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3166 4.83335 16.0083L4.29169 7.61664"
      stroke="#FF0080"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M8.60834 13.75H11.3833"
      stroke="#FF0080"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M7.91669 10.4167H12.0834"
      stroke="#FF0080"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

const QuestionIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512 282.784 0 512.017-229.216 512.017-512C1024.017 229.232 794.785 0 512 0zm0 961.008c-247.024 0-448-201.984-448-449.01 0-247.024 200.976-448 448-448s448.017 200.977 448.017 448S759.025 961.009 512 961.009zm-47.056-160.529h80.512v-81.248h-80.512zm46.112-576.944c-46.88 0-85.503 12.64-115.839 37.889-30.336 25.263-45.088 75.855-44.336 117.775l1.184 2.336h73.44c0-25.008 8.336-60.944 25.008-73.84 16.656-12.88 36.848-19.328 60.56-19.328 27.328 0 48.336 7.424 63.073 22.271 14.72 14.848 22.063 36.08 22.063 63.664 0 23.184-5.44 42.976-16.368 59.376-10.96 16.4-29.328 39.841-55.088 70.322-26.576 23.967-42.992 43.231-49.232 57.807-6.256 14.592-9.504 40.768-9.744 78.512h76.96c0-23.68 1.503-41.136 4.496-52.336 2.975-11.184 11.504-23.823 25.568-37.888 30.224-29.152 54.496-57.664 72.88-85.551 18.336-27.857 27.52-58.593 27.52-92.193 0-46.88-14.176-83.408-42.577-109.568-28.416-26.176-68.272-39.248-119.568-39.248z"></path>
  </svg>
);

const WarningIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    fill="currentColor"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 123.996 123.996"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path d="M9.821,118.048h104.4c7.3,0,12-7.7,8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5,0l-52.2,92.5 C-2.179,110.348,2.521,118.048,9.821,118.048z M70.222,96.548c0,4.8-3.5,8.5-8.5,8.5s-8.5-3.7-8.5-8.5v-0.2c0-4.8,3.5-8.5,8.5-8.5 s8.5,3.7,8.5,8.5V96.548z M57.121,34.048h9.801c2.699,0,4.3,2.3,4,5.2l-4.301,37.6c-0.3,2.7-2.1,4.4-4.6,4.4s-4.3-1.7-4.6-4.4 l-4.301-37.6C52.821,36.348,54.422,34.048,57.121,34.048z"></path>
      </g>
    </g>
  </svg>
);

const EditIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.05 3.00002L4.20835 10.2417C3.95002 10.5167 3.70002 11.0584 3.65002 11.4334L3.34169 14.1334C3.23335 15.1084 3.93335 15.775 4.90002 15.6084L7.58335 15.15C7.95835 15.0834 8.48335 14.8084 8.74168 14.525L15.5834 7.28335C16.7667 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2334 1.75002 11.05 3.00002Z"
      stroke="#979797"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M9.90833 4.20831C10.2667 6.50831 12.1333 8.26665 14.45 8.49998"
      stroke="#979797"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M2.5 18.3333H17.5"
      stroke="#979797"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

const PencilSquareIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={size}
    height={size}
  >
    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
  </svg>
);

const MenuOpenIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    height={size}
    width={size}
    stroke="currentColor"
    fill="none"
    strokeWidth="0"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16M4 18h7"
    ></path>
  </svg>
);

const MenuCloseIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    height={size}
    width={size}
    stroke="currentColor"
    fill="none"
    strokeWidth="0"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16M4 18h16"
    ></path>
  </svg>
);

const ArrowDownIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 512 512"
  >
    <path d="M98.9,184.7l1.8,2.1l136,156.5c4.6,5.3,11.5,8.6,19.2,8.6c7.7,0,14.6-3.4,19.2-8.6L411,187.1l2.3-2.6  c1.7-2.5,2.7-5.5,2.7-8.7c0-8.7-7.4-15.8-16.6-15.8v0H112.6v0c-9.2,0-16.6,7.1-16.6,15.8C96,179.1,97.1,182.2,98.9,184.7z" />
  </svg>
);

const ChevronDownIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={size}
    height={size}
  >
    <path
      fillRule="evenodd"
      d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
      clipRule="evenodd"
    />
  </svg>
);

const EllipsisIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <rect width="100%" height="100%" fill="transparent" />
    <g>
      <path
        strokeLinecap="round"
        d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5z"
        transform="matrix(.9 0 0 .9 -.872 1.2)"
        vectorEffect="non-scaling-stroke"
      />
      <path
        strokeLinecap="round"
        d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm0 6a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5z"
        transform="matrix(.9 0 0 .9 3.272 1.2)"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);

const CalcIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
    />
  </svg>
);

const TruckIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
    />
  </svg>
);

const MarkerIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    />
  </svg>
);

const ShareIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
    />
  </svg>
);

const XMarkIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const CheckOneIcon: React.FC<IconSvgProps> = ({ size = 28 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9997 25.6667C17.2213 25.6667 20.138 24.3609 22.2492 22.2496C24.3605 20.1383 25.6663 17.2217 25.6663 14C25.6663 10.7784 24.3605 7.86174 22.2492 5.75046C20.138 3.63921 17.2213 2.33337 13.9997 2.33337C10.778 2.33337 7.86137 3.63921 5.75009 5.75046C3.63885 7.86174 2.33301 10.7784 2.33301 14C2.33301 17.2217 3.63885 20.1383 5.75009 22.2496C7.86137 24.3609 10.778 25.6667 13.9997 25.6667Z"
      fill="#F5F5F5"
      stroke="url(#paint0_linear_1409_1481)"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M9.33301 14L12.833 17.5L19.833 10.5"
      stroke="url(#paint1_linear_1409_1481)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1409_1481"
        x1="0.0910928"
        y1="25.6667"
        x2="28.4296"
        y2="21.603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31881B" />
        <stop offset="1" stopColor="#38AB02" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1409_1481"
        x1="8.32415"
        y1="17.5"
        x2="20.7632"
        y2="14.8244"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31881B" />
        <stop offset="1" stopColor="#38AB02" />
      </linearGradient>
    </defs>
  </svg>
);

const CheckIcon: React.FC<IconSvgProps> = ({ size = 28 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 12"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m1.176 5.5 5 5 8-9"
    ></path>
  </svg>
);

const DoubleCheckIcon: React.FC<IconSvgProps> = ({ size = 28 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 21 12"
  >
    <path
      stroke="#175DE5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m1.176 5.5 5 5 8-9m-9 3 1 1m5 5 8-9"
    ></path>
  </svg>
);

const AcademicCapIcon: React.FC<IconSvgProps> = ({ size = 32 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 18.6667L28 12L16 5.33337L4 12L16 18.6667ZM16 18.6667L24.2121 14.1044C24.9357 15.9288 25.3333 17.918 25.3333 20C25.3333 20.9351 25.2531 21.8514 25.0992 22.7425C21.6191 23.0809 18.4654 24.5342 16 26.7408C13.5346 24.5342 10.3809 23.0809 6.90082 22.7425C6.74689 21.8514 6.66667 20.935 6.66667 19.9999C6.66667 17.9179 7.06433 15.9288 7.78783 14.1044L16 18.6667ZM10.6667 26.6666V16.6667L16 13.7037"
      stroke="#175DE5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowNarrowRightIcon: React.FC<IconSvgProps> = ({ size = 28 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="white"
    className="bgWhite rounded-full"
  >
    <path
      d="M14.0383 10.0426L18.1238 10.0426M18.1238 10.0426L18.1238 14.1281M18.1238 10.0426L8.93142 19.235"
      stroke="url(#paint0_linear_961_10883)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_961_10883"
        x1="8.72277"
        y1="26.3584"
        x2="17.6437"
        y2="8.79351"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C90E7" />
        <stop offset="1" stopColor="#175DE5" />
      </linearGradient>
    </defs>
  </svg>
);

const EditNoteIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M8.75 3.75H5C4.17157 3.75 3.5 4.42158 3.5 5.25V13.5C3.5 14.3284 4.17157 15 5 15H13.25C14.0784 15 14.75 14.3284 14.75 13.5V9.75M13.6893 2.68934C14.2751 2.10355 15.2249 2.10355 15.8107 2.68934C16.3964 3.27513 16.3964 4.22487 15.8107 4.81066L9.37132 11.25H7.25L7.25 9.12868L13.6893 2.68934Z"
      stroke="#175DE5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SettingsIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={size}
    height={size}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

const SupportIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={size}
    height={size}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
    />
  </svg>
);

const SubscriptionIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={size}
    height={size}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const PhoneIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
      clipRule="evenodd"
    />
  </svg>
);

const LinkIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
    width={size}
    height={size}
  >
    <path
      fillRule="evenodd"
      d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);

const UserIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={size}
    height={size}
  >
    <path
      fillRule="evenodd"
      d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
      clipRule="evenodd"
    />
  </svg>
);

const InfoIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width={size}
    height={size}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
    />
  </svg>
);

const BanknotesIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={size}
    height={size}
  >
    <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
    <path
      fillRule="evenodd"
      d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z"
      clipRule="evenodd"
    />
    <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z" />
  </svg>
);

const SparklesIcon: React.FC<IconSvgProps> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={size}
    height={size}
  >
    <path
      fillRule="evenodd"
      d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z"
      clipRule="evenodd"
    />
  </svg>
);

export {
  GoogleIcon,
  OutlookIcon,
  DoneIcon,
  MailIcon,
  TemplateIcon,
  BookmarkIcon,
  BookmarkFillIcon,
  UserIcon,
  ReviewIcon,
  BellIcon,
  PlusIcon,
  CrossIcon,
  GMailIcon,
  YahooIcon,
  SMTPIcon,
  TrashIcon,
  QuestionIcon,
  WarningIcon,
  EditIcon,
  PencilSquareIcon,
  MenuOpenIcon,
  MenuCloseIcon,
  ArrowDownIcon,
  ChevronDownIcon,
  EllipsisIcon,
  CalcIcon,
  TruckIcon,
  MarkerIcon,
  ShareIcon,
  XMarkIcon,
  CheckOneIcon,
  CheckIcon,
  DoubleCheckIcon,
  AcademicCapIcon,
  ArrowNarrowRightIcon,
  EditNoteIcon,
  SettingsIcon,
  SupportIcon,
  SubscriptionIcon,
  PhoneIcon,
  LinkIcon,
  BanknotesIcon,
  InfoIcon,
  SparklesIcon,
};
