import { LoadNote } from "@/types";
import { callLoadConnectApi } from "./api.service";

type queryType = {
  [key: string]: any;
};

export async function getLoadNotes({
  skip = 0,
  take = 9999,
  ...params
}: { skip?: number; take?: number } & queryType): Promise<LoadNote[]> {
  let query = "";
  Object.keys(params).map(
    (item: string) => (query += `where[${item}][equals]=${params[item]}&`)
  );
  query += `&skip=${skip}&take=${take}`;
  query += `&orderBy[createdAt]=desc`;

  const resp = await callLoadConnectApi(
    `/loadUserRelations?${query}`,
    "get",
    true,
    {}
  );
  const info: LoadNote[] = resp.data;
  return info;
}

export async function addLoadNote(note: LoadNote): Promise<LoadNote> {
  const resp = await callLoadConnectApi(
    `/loadUserRelations`,
    "post",
    true,
    note
  );
  const info: LoadNote = resp.data;
  return info;
}

export async function patchLoadNote(note: any): Promise<LoadNote> {
  const resp = await callLoadConnectApi(
    `/loadUserRelations/${note.id}`,
    "patch",
    true,
    note
  );
  const info: LoadNote = resp.data;
  return info;
}

export async function deleteLoadNote(note: any) {
  await callLoadConnectApi(
    `/loadUserRelations/${note.id}`,
    "delete",
    true,
    note
  );
}
