import { Outlet, Navigate, useSearchParams } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/redux/store";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userMe, userUnfo } from "@/server/auth.service";
import {
  setAccessToken,
  setAuthData,
  setRefreshToken,
} from "@/redux/slices/auth-slice";
// import SubscriptionAlarm from "@/components/subscription-alarm";
import { sendAmplitudeData, setAmplitudeUserId } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { OnboardingModal } from "@/components/onboarding-modal";
import { setUserInfo } from "@/redux/slices/user-info-slice";
import { AccountNotVerifiedModal } from "@/components/account-not-verified-modal";
import { ExtensionHandlers } from "@/components/extension-handlers";
import { ReloadLoadboardModal } from "@/components/reload-loadboard-modal";
import { Loader } from "@/components/loader";

const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [query] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const { isAuth, accessToken, refreshToken, email, accountVerified } =
    useSelector((state: RootState) => state.authReducer.value);

  try {
    (window as any).intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "j1em1nwh",
      email: email,
    };
  } catch (e) {}

  const isMapPage = location.pathname.startsWith("/loadinfo");

  useEffect(() => {
    const authHandler = async () => {
      try {
        setLoading(true);

        const token = query.get("accessToken") || accessToken;
        const refrToken = query.get("refreshToken") || refreshToken;
        dispatch(setAccessToken(token));
        dispatch(setRefreshToken(refrToken));

        const data = await userMe();

        setAmplitudeUserId(data.email);
        sendAmplitudeData(AMPLITUDE_EVENTS.dashboardAccountLoggedIn);

        dispatch(
          setAuthData({
            isAuth: true,
            accessToken: token,
            refreshToken: refrToken,
            email: data.email,
            extensionToken: data.extensionToken,
            features: data.features,
            limitEmails: data.limitEmails,
            limitTempl: data.limitTempl,
            userId: data.userId,
            emails: data.emails,
            createdAt: data.createdAt,
            trialDays: data.trialDays,
            accountVerified: data.accountVerified,
            billingPlan: data.billingPlan,
          })
        );
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };

    authHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showOnboarding]);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const data = await userUnfo();

        dispatch(setUserInfo(data));
        if (!data.userType) {
          setShowOnboarding(true);
          setShowWelcomeModal(true);
        }
      } catch (e) {}
    };

    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const closeOnboarding = () => setShowOnboarding(false);

  if (loading) {
    return <Loader />;
  }

  if (!isAuth) {
    if (query.get("accessToken")) {
      query.set("redirect", query.get("redirect") || location.pathname);
      return <Navigate to={`/sign-in?${query.toString()}`} />;
    }

    return <Navigate to="/sign-in" />;
  }

  return (
    <main className="w-screen h-screen flex bg-white">
      {showOnboarding ? (
        <OnboardingModal show={showOnboarding} onClose={closeOnboarding} />
      ) : (
        <>
          {!accountVerified && <AccountNotVerifiedModal />}
          <ReloadLoadboardModal show={showWelcomeModal} />
        </>
      )}
      <ExtensionHandlers />
      <Sidebar />
      <section className="relative h-full w-full flex flex-col overflow-auto">
        <Header isMapPage={isMapPage} />
        <div className="w-full h-full max-w-[1280px] mx-auto">
          <Outlet />
        </div>
        {!isMapPage && <Footer />}
      </section>
    </main>
  );
};

export default MainLayout;
