import { NavLink, useLocation } from "react-router-dom";
import { FC, ReactNode, useCallback } from "react";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { sendAmplitudeData } from "@/analytics";
import { Badge, cn, Divider } from "@nextui-org/react";

interface IProps {
  data: {
    label: string;
    icon: ReactNode;
    href: string;
    last?: boolean;
  };
  collapsed: boolean;
  isAuth?: boolean;
  onClick: any;
  withBadge?: boolean;
}

const TABS_EVENTS: Record<string, string> = {
  "/emailTemplate": AMPLITUDE_EVENTS.dashboardMyTemplatesTabClicked,
  "/load": AMPLITUDE_EVENTS.dashboardMyLoadsTabClicked,
  "/email": AMPLITUDE_EVENTS.dashboardMyEmailsTabClicked,
  "/pricing": AMPLITUDE_EVENTS.dashboardBillingTabClicked,
};

const SidebarItem: FC<IProps> = ({
  data,
  collapsed,
  isAuth,
  onClick,
  withBadge,
}) => {
  const { label, icon, href, last } = data;
  const location = useLocation();

  const handleClick = useCallback(() => {
    onClick && onClick();
    const event = TABS_EVENTS[href];
    if (event) {
      sendAmplitudeData(event);
    }
  }, [href, onClick]);

  const to = isAuth ? href : location.pathname;

  const getNavClassName = (isActive: boolean) => {
    return cn(
      "h-9 m-1 px-3 py-2 flex items-center gap-3 rounded-md [&>*]:text-gray-600 [&>svg]:shrink-0 hover:bg-brand-700/10 overflow-hidden",
      isActive && "bg-brand-700/10 [&>*]:text-brand-700"
    );
  };

  return (
    <>
      <NavLink
        to={to}
        onClick={handleClick}
        className={({ isActive }) => getNavClassName(isActive)}
      >
        {withBadge ? (
          <Badge size="sm" content="" showOutline={false} color="danger">
            {icon}
          </Badge>
        ) : (
          icon
        )}
        {!collapsed && <span className="text-nowrap text-[15px]">{label}</span>}
      </NavLink>
      {last && <Divider className="my-2 bg-gray-200" />}
    </>
  );
};

export default SidebarItem;
