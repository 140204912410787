import { RootState } from "@/redux/store";
import { Chip } from "@nextui-org/react";
import { useSelector } from "react-redux";

export const PlanInfo = () => {
  const user = useSelector((state: RootState) => state.authReducer.value);

  const { features, trialDays, createdAt } = user;
  const isUnlimitedEmails = features.includes("email");

  const daysBeetween = Math.floor(
    (new Date().getTime() - new Date(createdAt).getTime()) / (1000 * 3600 * 24)
  );
  const leftTrialDays = trialDays - daysBeetween;
  const isTrialExpired = leftTrialDays < 1;

  const literal = isUnlimitedEmails
    ? "Active Plan"
    : isTrialExpired
    ? "Trial expired"
    : "Trial";

  const color = isUnlimitedEmails
    ? "primary"
    : isTrialExpired
    ? "danger"
    : "default";

  return (
    <h2 className="absolute font-medium text-2xl px-8 top-5 flex gap-5 items-center">
      Welcome to LoadConnect
      <Chip variant="flat" color={color}>
        {literal}
      </Chip>
    </h2>
  );
};
