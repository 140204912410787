import LoadContent from "./load-content";
import { LoadInfo, LoadNote, McReview } from "@/types";
import Loading from "@/components/loading";
import { ChevronDownIcon } from "@/components/icons";
import { useState } from "react";
import { cn } from "@nextui-org/react";

const LoadControl = ({
  loadInfo,
  routeInfo,
  loadNote,
  loadSaving,
  onSaveLoad,
  onShareLoad,
  onSaveNote,
  noteSaving,
  loading,
  onPlaceChange,
  reviews,
  onOpenReview,
  onFocusPlace,
  updating,
}: {
  loadInfo: LoadInfo;
  routeInfo: LoadInfo["routeInfo"];
  loadNote: LoadNote;
  loadSaving: boolean;
  onSaveLoad: any;
  onShareLoad: any;
  onSaveNote?: any;
  noteSaving?: boolean;
  loading: boolean;
  onPlaceChange: any;
  reviews: McReview[];
  onOpenReview: any;
  onFocusPlace?: any;
  updating: boolean;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <div
        className={cn(
          "relative rounded bg-white h-screen flex flex-col transition-all w-[480px] z-50 shadow",
          open ? "max-w-full" : "max-w-0"
        )}
      >
        <div
          onClick={() => setOpen(!open)}
          className="absolute w-5 h-12 bg-white top-[calc(50%_-_24px)] rounded-r-md -right-5 border-l flex justify-center items-center cursor-pointer"
        >
          <span className={open ? "rotate-90" : "-rotate-90"}>
            <ChevronDownIcon size={16} />
          </span>
        </div>
        <div className="flex-grow h-full p-8">
          {!loading ? (
            <LoadContent
              reviews={reviews}
              loadNote={loadNote}
              loadInfo={loadInfo}
              routeInfo={routeInfo}
              loadSaving={loadSaving}
              onSaveLoad={onSaveLoad}
              onShareLoad={onShareLoad}
              noteSaving={noteSaving}
              onSaveNote={(note: string) => onSaveNote && onSaveNote(note)}
              onPlaceChange={onPlaceChange}
              onOpenReivew={onOpenReview}
              onFocusPlace={onFocusPlace}
              updating={updating}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoadControl;
