import { RootState } from "@/redux/store";
import { Avatar, Card } from "@nextui-org/react";
import { useSelector } from "react-redux";

// const FEATURES_TERMS: Record<string, string> = {
//   filters: "Filters",
//   rpmLoadboard: "RPM Calculator",
//   googleMapsIcon: "Google Maps Integration",
//   email: "Send Email Button",
//   loadconnectMap: "LoadConnect Map Button",
//   popup: "Load Info Popup",
//   googleRpm: "Google RPM",
// };

export const ActivePlan = () => {
  const { email } = useSelector((state: RootState) => state.authReducer.value);

  const billingStatus = useSelector(
    (state: RootState) => state.billingInfo.status
  );

  return (
    <div className="w-full">
      <div className="flex justify-start items-center gap-4 mb-10">
        <Avatar
          color="primary"
          classNames={{
            base: "w-16 h-16 bg-brand-700/20 outline-1 outline-offset-2 outline-brand-700/20",
            name: "text-3xl text-brand-700",
          }}
          name={email?.charAt(0).toUpperCase()}
        ></Avatar>
        <h3>{email}</h3>
      </div>
      <Card
        classNames={{
          base: "w-fit p-6 shadow-md border rounded-xl",
        }}
      >
        <h3 className="w-full font-normal text-xl">
          Your plan is <span className="text-brand-700">{billingStatus}</span>
        </h3>
      </Card>
    </div>
  );
};
