import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserBillingStatus } from "@/types/index";

interface BillingInfo {
  status: UserBillingStatus | "";
}

const initialState: BillingInfo = {
  status: "",
};

export const billingInfo = createSlice({
  name: "billingInfo",
  initialState,
  reducers: {
    setBillingStatus: (state, action: PayloadAction<UserBillingStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { setBillingStatus } = billingInfo.actions;

export default billingInfo.reducer;
