import { MyLoadBoards } from "./components/MyLoadBoards";
import { PlanInfo } from "./components/PlanInfo";
import { Tutorials } from "./components/Tutorials";

const Welcome = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <PlanInfo />
      <MyLoadBoards />
      <Tutorials />
    </div>
  );
};

export default Welcome;
