import { Account, SMTPProvider } from "@/types";
import { ModalBody, ModalHeader } from "@nextui-org/modal";
import { FC, useState } from "react";
import { Divider } from "@nextui-org/react";
import { SMTPForm } from "./smtpForm";
import { SMTPProviderSelect } from "./smptProviderSelect";
import { SMTP_PROVIDERS } from "@/constants/smtpProviders";

interface Props {
  account: Account | null;
  onBack: () => void;
  onAddSMTP: () => void;
}

const SMTPService: FC<Props> = ({ account, onBack, onAddSMTP }) => {
  const [provider, setProvider] = useState<SMTPProvider | null>({
    name: "Other",
    host: "",
    portWithSSL: "",
    port: "",
  });

  const handleProviderChange = (value: string) => {
    const provider = SMTP_PROVIDERS.find((p) => p.host === value);
    if (provider) setProvider(provider);
  };

  return (
    <>
      <ModalHeader>
        <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
          Add new account
        </h3>
      </ModalHeader>
      <ModalBody>
        <div className="flex h-[520px] gap-5 pt-4 pb-10">
          <SMTPProviderSelect
            activeProvider={provider}
            setActiveProvider={handleProviderChange}
          />
          <Divider orientation="vertical" />
          <SMTPForm
            provider={provider}
            account={account}
            onBack={onBack}
            onAddSMTP={onAddSMTP}
          />
        </div>
      </ModalBody>
    </>
  );
};

export default SMTPService;
