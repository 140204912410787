import { useEffect, useMemo } from "react";
import Plans from "./components/Plans";
import { getUserBilling } from "@/server/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { setBillingStatus } from "@/redux/slices/billing-slice";
import { RootState } from "@/redux/store";
import { ActivePlan } from "./components/ActivePlan";
import { ErrorPlan } from "./components/ErrorPlan";
import { Loader } from "@/components/loader";

const Pricing = () => {
  const billingStatus = useSelector(
    (state: RootState) => state.billingInfo.status
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!billingStatus)
      getUserBilling().then((data) => {
        dispatch(setBillingStatus(data));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const renderUIBasedOnBillingStatus = useMemo(() => {
    switch (billingStatus) {
      case "past_due":
      case "unpaid":
        return <ErrorPlan />;
      case "paused":
      case "active":
        return <ActivePlan />;
      case "inactive":
      case "canceled":
        return <Plans />;
      default:
        <Loader />;
    }
  }, [billingStatus]);

  return (
    <div
      className="flex flex-col flex-grow items-center py-6 overflow-auto"
      id="Pricing"
    >
      {renderUIBasedOnBillingStatus}
    </div>
  );
};

export default Pricing;
