import { FC } from "react";
import { Button, Card, CardBody, cn, Progress } from "@nextui-org/react";

import { ReactComponent as SparkleIcon } from "@/assets/icons/sparkle-icon.svg";
import { ReactComponent as MoneyIcon } from "@/assets/icons/money-icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { NavLink } from "react-router-dom";

interface Props {
  isOpen: boolean;
}

export const SidebarEmailStats: FC<Props> = ({ isOpen }) => {
  const features = useSelector(
    (state: RootState) => state.authReducer.value.features
  );
  const leftEmails = useSelector(
    (state: RootState) => state.authReducer.value.emails
  );

  const isUnlimitedEmails = features.includes("email");

  const emailLiteral = leftEmails === 1 ? "email" : "emails";

  const percent = (100 / 50) * leftEmails;

  const color =
    leftEmails > 20 ? "primary" : leftEmails > 0 ? "warning" : "danger";
  const textColor =
    leftEmails > 20
      ? "text-brand-700"
      : leftEmails > 0
      ? "text-warning-500"
      : "text-danger-500";

  const UpgradeButton = (
    <Button
      as={NavLink}
      startContent={<SparkleIcon />}
      fullWidth
      variant="bordered"
      color={color}
      radius="lg"
      isIconOnly={!isOpen}
      to="/pricing"
    >
      {isOpen && "Upgrade now"}
    </Button>
  );

  if (!isOpen)
    return (
      <div className="flex flex-col gap-2">
        {isUnlimitedEmails ? (
          <span className="flex flex-col items-center gap-2 text-brand-700">
            <MoneyIcon />
            <span className="text-sm text-center">Active Plan</span>
          </span>
        ) : (
          <>
            <span className="text-xs text-gray-700">
              <span className={cn("text-sm", textColor)}>{leftEmails}</span>
              /50
            </span>
            {UpgradeButton}
          </>
        )}
      </div>
    );

  return (
    <Card
      className={cn(
        "w-[188px] shadow-none p-3 rounded-xl",
        isUnlimitedEmails ? "bg-primary-50" : "bg-gray-50"
      )}
    >
      <CardBody className="flex flex-col gap-3 p-0">
        <div className="flex flex-col gap-2">
          {isUnlimitedEmails ? (
            <span className="flex items-center gap-3 text-brand-700">
              <MoneyIcon />
              <span className="flex flex-col">
                <span className="text-[15px]">Active Plan</span>
                <span className="text-[15px]">Unlimited emails</span>
              </span>
            </span>
          ) : (
            <span className="text-sm text-gray-700">
              <span className={cn("text-base", textColor)}>{leftEmails}</span>
              {leftEmails <= 50 ? "/50" : ""} {emailLiteral} left
            </span>
          )}
          <Progress color={color} value={percent} size="sm" />
        </div>
        {isUnlimitedEmails ? (
          <Button
            as={NavLink}
            to="https://loadconnect.io/contact/"
            color="primary"
            radius="lg"
            target="_blank"
            variant="bordered"
          >
            Contact Us
          </Button>
        ) : (
          UpgradeButton
        )}
      </CardBody>
    </Card>
  );
};
