import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import { PERSIST_STORE_NAME } from "@/config/app.config";
import storage from "redux-persist/lib/storage";

import authReducer from "../slices/auth-slice";
import userInfoReducer from "../slices/user-info-slice";
import billingInfo from "../slices/billing-slice";

const persistConfig: PersistConfig<any> = {
  key: PERSIST_STORE_NAME,
  keyPrefix: "",
  storage,
  blacklist: ["userInfo", "billingInfo"],
};

export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      authReducer,
      userInfoReducer,
      billingInfo,
    })
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([]),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
