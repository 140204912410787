"use client";

import { FC, useState } from "react";
import SidebarLogo from "./sidebar-logo";
import SidebarItem from "./sidebar-item";

import { useLocation } from "react-router-dom";

import { Button, cn } from "@nextui-org/react";

import { ReactComponent as MenuIcon } from "@/assets/icons/align-icon.svg";
import { ReactComponent as HomeIcon } from "@/assets/icons/grid-icon.svg";
import { ReactComponent as MailIcon } from "@/assets/icons/mail-icon.svg";
import { ReactComponent as EditIcon } from "@/assets/icons/edit-icon.svg";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark-icon.svg";
import { ReactComponent as BillingIcon } from "@/assets/icons/dollar-icon.svg";
import { SidebarEmailStats } from "./sidebar-email-stats";
import { SidebarTrialStats } from "./sidebar-trial-stats";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

interface Props {
  onRedirect?: (url: string) => void;
}

const SidebarWrapper: FC<Props> = ({ onRedirect }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const isMapPage = location.pathname.startsWith("/loadinfo");
  const { isAuth, accountVerified } = useSelector(
    (state: RootState) => state.authReducer.value
  );

  const toggleSidebar = () => setIsOpen((prev) => !prev);

  const sidebarCn = cn(
    "sticky border-r border-gray-200 transition-all max-w-64 py-5 flex flex-col gap-8 overflow-hidden",
    isOpen && !isMapPage ? "w-64" : "w-14"
  );

  const NAVIGATION_ITEMS = [
    {
      label: "Home",
      icon: <HomeIcon />,
      href: "/",
    },
    {
      label: "My account",
      icon: <MailIcon />,
      href: "/email",
      withBadge: !accountVerified,
    },
    {
      label: "My templates",
      icon: <EditIcon />,
      href: "/emailTemplate",
    },
    {
      label: "My loads",
      icon: <BookmarkIcon />,
      href: "/load",
      last: true,
    },
    {
      label: "Billing",
      icon: <BillingIcon />,
      href: "/pricing",
    },
  ];

  return (
    <aside className={sidebarCn}>
      <div className="w-full flex justify-between items-center px-3">
        <SidebarLogo onClick={toggleSidebar} isOpen={isOpen && !isMapPage} />
        <Button
          onClick={toggleSidebar}
          isIconOnly
          variant="flat"
          className={cn(
            (!isOpen || isMapPage) && "hidden",
            "text-gray-500 h-fit !w-fit !min-w-fit !bg-black/0 data-[hover=true]:!text-brand-700"
          )}
        >
          <MenuIcon />
        </Button>
      </div>

      <div className="w-full flex flex-col flex-grow">
        {NAVIGATION_ITEMS.map((item) => (
          <SidebarItem
            key={item.label}
            data={item}
            isAuth={isAuth}
            collapsed={!(isOpen && !isMapPage)}
            onClick={onRedirect}
            withBadge={item.withBadge}
          />
        ))}
      </div>

      <div className="w-full flex flex-col gap-5 items-center px-3">
        <SidebarTrialStats isOpen={isOpen && !isMapPage} />
        <SidebarEmailStats isOpen={isOpen && !isMapPage} />
      </div>
    </aside>
  );
};

export default SidebarWrapper;
